import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import searchUIClient from "@/apis/searchUIClient";
import SntAdvancedSearchTableViewCard from "@/components/SntTableViewCard/SntAdvancedSearchTableViewCard";

import SntPencilIcon from "@/components/Icons/SntPencilIcon";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import SntLink from "@/components/ReactBootstrap/SntLink";
import AdminLayout from "@/containers/AdminLayout/AdminLayout";
import ActionToolEnum from "@/containers/AdminLayout/Toolbar/ActionToolEnum";
import ActionToolType from "@/containers/AdminLayout/Toolbar/ActionToolType";

import { AutomationRuleRoute as PageRoute } from "@/cleanup/utils/routesUtil/routesUtil";
import { useOrg } from "@/contexts/OrgContext";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import useDeleteDialog from "@/cleanup/widgets/dialog/useDeleteDialog";
import automationRuleClient from "@/apis/automationRuleClient";
import {
  ACTION_MAPPING,
  CONDITION_MAPPING,
} from "./components/automationRuleUtils";
import AlertSwitch from "@/pages/AlertRule/AlertSwitch";
import SntLabIcon from "@/components/Icons/SntLabIcon";

const PAGE_NAME = "AUTOMATION";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "AUTOMATION",
  filtersVisibleAtInit: ["name"],
  optionsSettings: {},
};

const AutomationRule = ({ reload }) => {
  const { orgId } = useOrg();

  const [count, setCount] = useState(0);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setCount((prevCount) => {
        const newCount = prevCount + 1;
        return newCount;
      });
    }, 100);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [reload, orgId]);

  if (!count) return <></>;

  return <AutomationRule1 key={count} orgId={orgId} />;
};

const AutomationRule1 = ({ orgId }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();
  const history = useHistory();

  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap } = loginInfo;

  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  const filterList = [
    {
      id: ActionToolEnum.save_filter,
      title: language.save_filter,
      type: ActionToolType.filter,
    },
    {
      id: ActionToolEnum.load_filter,
      title: language.load_filter,
      type: ActionToolType.filter,
    },
  ];

  const allColumns = useMemo(() => {
    const allColumns = [
      {
        key: "name",
        Cell: ({ cell, value }) => {
          if (permissionsMap["AUTOMATION_VIEW"]) {
            return (
              <SntLink
                onClick={(event) => {
                  event.preventDefault();
                  history.push(
                    PageRoute.getUpdatePage({
                      type: "view",
                      id: cell.row.original.id,
                      orgId: orgId,
                    })
                  );
                }}
              >
                {value}
              </SntLink>
            );
          }
          return <>{value}</>;
        },
      },
      {
        key: "condition",
        Cell: ({ cell, value }) => {
          return (
            <>
              {CONDITION_MAPPING[cell.row.original.conditionType]?.getLabel(
                language
              ) || ""}
            </>
          );
        },
        cellFilterValue: (data) => {
          return [data.conditionType];
        },
        cellCopyData: (data) => {
          return [data.conditionType];
        },
      },
      {
        key: "actionType",
        Cell: ({ value }) => {
          return <>{ACTION_MAPPING[value]?.getLabel(language) || ""}</>;
        },
        cellFilterValue: (data) => {
          return [data.actionType];
        },
        cellCopyData: (data) => {
          return [data.actionType];
        },
      },
      {
        key: "active",
        Cell: ({ cell, value }) => {
          let full = cell.row.original;

          if (full.id) {
            return (
              <AlertSwitch
                active={full.active}
                disabled={!permissionsMap["AUTOMATION_MANAGE"]}
                onSwitchChange={onSwitchChange}
                id={full.id}
              />
            );
          }

          return <>{full.active}</>;
        },
        cellFilterValue: (data) => {
          return [data.active];
        },
        cellCopyData: (data) => {
          return [data.active];
        },
      },
    ];
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColumnInit = useMemo(
    () => ["name", "condition", "action", "active"],
    []
  );
  
  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.action_key,
        disableSortBy: true,
        canResize: false,
        immovable: true,
        Cell: ({ cell }) => {
          return (
            <>
              {permissionsMap["AUTOMATION_MANAGE"] && (
                <>
                  <SntActionButton
                    to={PageRoute.getUpdatePage({
                      type: "edit",
                      id: cell.row.original.id,
                      orgId: orgId,
                    })}
                  >
                    <SntPencilIcon />
                  </SntActionButton>
                  <SntActionButton
                    title={language.delete_key}
                    onClick={(e) => onDeleteClicked(e, cell.row.original)}
                  >
                    <SntDeleteIcon />
                  </SntActionButton>
                </>
              )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      automationRuleClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.refresh();
        })
        .catch((error) => {
          let message = language.request_error_key;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      table.current.setLoading(true);
      params["selectedOrgId"] = orgId;
      searchUIClient.search({ ...params }).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);
            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const onSwitchChange = (checked, id) => {
    automationRuleClient
      .active(id, checked)
      .then(() => {
        table.current.refresh();
        console.log("update active successfully!");
      })
      .catch((error) => {
        console.log("failed to update active!");
      });
  };

  return (
    <div>
      <DeleteDialog />

      <AdminLayout
        browserTabTitle={language.automation_rule_key}
        headerTitle={language.automation_rule_key}
        extraTitle={
          <SntLabIcon
            className={"ms-2"}
            style={{ marginTop: "-3px" }}
            title={language.lab_feature_key}
          />
        }
        pageName={PAGE_NAME}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        filterList={filterList}
        showPermissionWarning
        newButton={{
          isShow: permissionsMap["AUTOMATION_MANAGE"],
          link: PageRoute.getCreatePage(),
          label: language.create_automation_rule_key,
        }}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={extraCols}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
              isDisabledCopyData={false}
              isDisabbledAddFilter={false}
              onFilterClicked={(action, key, filterKeys, query) => {
                setForceFilterAction({ action, key, filterKeys, query });
              }}
              filterMap={filterMap}
            />
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </div>
  );
};

export default AutomationRule;
