import { apiClient } from "./apiClient";

export default {
  getById(id) {
    return apiClient.get("/rest/wifi_cellular_mismatch/" + id);
  },

  delete(id, deleteAll) {
    return apiClient.delete(
      deleteAll
        ? `/rest/wifi_cellular_mismatch/all/${id}`
        : `/rest/wifi_cellular_mismatch/${id}`
    );
  },

  createPrivateCellularTower(id, location) {
    return apiClient.post(
      `/rest/wifi_cellular_mismatch/private_cell_towers/${id}`,
      location
    );
  },

  deletePrivateCellularTower(id) {
    return apiClient.delete(
      `/rest/wifi_cellular_mismatch/private_cell_towers/${id}`
    );
  },
};
